import React from 'react';
import { isMobile } from 'react-device-detect';
import Fade from 'react-reveal/Fade';
import { SectionContainer } from '../Layout';

const Cover = () => (
  <SectionContainer className="bg-dark">
    <div className="bg-dark wl-cover">
      <div className="mb-4 pb-3">
        <img src="/assets/images/logo_ledger.svg" alt="Ledger" />
      </div>
      <Fade bottom>
        <div>
          <h1 className="text-white fs-hero hero m-0">YOUR CRYPTO. YOUR LIFE.</h1>
        </div>
      </Fade>
      <Fade bottom delay={300}>
        <div className="wl-image-container">
          <img
            className={`${isMobile ? 'w-100' : ''}`}
            src="/assets/images/card_promo.svg"
            alt="Card promo"
          />
        </div>
      </Fade>
      <Fade bottom delay={600}>
        <div className={`mt-5 pt-2 ${isMobile ? 'w-100 px-3' : 'col-6'} mx-auto`}>
          <p className="text-white fs-f5 m-0">
            Be one of the first to get your hands on the new CL Card, integrated with Ledger, and
            operated by Baanx*. Can’t wait? To move up the line and get the chance to get a FREE
            physical card, just share the personal referral link we’ll give you when you register.
          </p>
          <p className="mt-3" style={{ fontSize: '12px', color: '#717070' }}>
            *The CL Card and its features are provided by Baanx Group Ltd (in the UK) Baanx US Corp
            (in the United States) and Frozen Time UNIPESSOAL LDA (in the EU).
          </p>
        </div>
      </Fade>
      <div className="mt-4 pt-3">
        <button className="btn btn-primary" type="button" data-toggle="modal" data-target="#modal">
          Join the waitlist
        </button>
      </div>
    </div>
  </SectionContainer>
);

export default Cover;
