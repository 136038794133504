import React from 'react';
import { isMobile } from 'react-device-detect';
import Fade from 'react-reveal/Fade';
import { SectionContainer } from '../Layout';

const Card = () => (
  <SectionContainer>
    <div className="card-layout">
      <div className="row">
        <div className="col-xl-6 col-12 text-xl-start p-0">
          <img src="/assets/images/card_body.svg" alt="Ledger card body" />
        </div>

        <div className="col-xl-6 col-12 my-auto text-xl-start">
          <Fade bottom>
            <div className={`d-block ${isMobile ? 'text-center' : 'text-left'}`}>
              <p className={`${!isMobile ? 'mt-0' : ''} mb-0`}>
                Integrated with Ledger’s unique digital security ecosystem, the CL Card allows users
                to to to to move assets quickly, seamlessly and securely between their secured
                wallet and card, using the Ledger Live app.
              </p>
              <p className="mt-2" style={{ fontSize: '16px', color: '#717070' }}>
                Be first in line to get your free physical card and early access to the crypto CL
                Card, integrated with Ledger.
              </p>
              <button
                className="btn btn-primary"
                type="button"
                data-toggle="modal"
                data-target="#modal">
                Join the waitlist
              </button>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  </SectionContainer>
);

export default Card;
