import React from 'react';
import { isMobile } from 'react-device-detect';
import Fade from 'react-reveal/Fade';
import { links } from '../../../../utils/constants';
import { SectionContainer } from '../Layout';

import LinkedIn from './partials/linked';
import Twitter from './partials/twitter';
import Facebook from './partials/facebook';

const Footer = () => (
  <SectionContainer className="bg-dark">
    <div className="footer-layout d-f">
      <div className={`${!isMobile ? 'd-flex' : ''}`}>
        <div className="d-flex flex-row justify-content-between align-items-start bg-dark pt-4 pb-3 col-3">
          <a href={links.baanx} target="_blank" rel="noreferrer">
            <img src="/assets/images/footer_logo.png" alt="Ledger" />
          </a>
        </div>
        <div className="bg-dark py-4 col-9">
          <Fade bottom>
            <div className="row">
              <div
                className="col-12 col-md-6 col-xl-3 d-flex flex-column justify-content-start"
                style={{ marginBottom: isMobile ? '3.438rem' : '0', paddingLeft: '1.75rem' }}>
                <div className="text-white wl-footer-header mb-1">Products & Services</div>
                <div className="text-white mt-2  wl-footer-link">
                  <a href={links.digital_banking} target="_blank" rel="noreferrer">
                    Digital Fintech
                  </a>
                </div>
                <div className="text-white mt-2  wl-footer-link">
                  <a href={links.payments} target="_blank" rel="noreferrer">
                    Payments
                  </a>
                </div>
                <div className="text-white mt-2  wl-footer-link">
                  <a href={links.compliance} target="_blank" rel="noreferrer">
                    Compliance as a Service
                  </a>
                </div>
                <div className="text-white mt-2  wl-footer-link">
                  <a href={links.digital_assets} target="_blank" rel="noreferrer">
                    Digital Assets
                  </a>
                </div>
              </div>
              <div
                className="col-12 col-md-6 col-xl-3 d-flex flex-column justify-content-start"
                style={{ marginBottom: isMobile ? '3.438rem' : '0', paddingLeft: '1.75rem' }}>
                <div className="text-white wl-footer-header mb-1">Company</div>
                <div className="text-white mt-2  wl-footer-link">
                  <a href={links.about_us} target="_blank" rel="noreferrer">
                    About Us
                  </a>
                </div>
                <div className="text-white mt-2  wl-footer-link">
                  <a href={links.careers} target="_blank" rel="noreferrer">
                    Careers
                  </a>
                </div>
                <div className="text-white mt-2  wl-footer-link">
                  <a href={links.newsletter} target="_blank" rel="noreferrer">
                    Newsletter
                  </a>
                </div>
                <div className="text-white mt-2  wl-footer-link">
                  <a href={links.blog} target="_blank" rel="noreferrer">
                    Blog
                  </a>
                </div>
                <div className="text-white mt-2  wl-footer-link">
                  <button
                    type="button"
                    style={{
                      background: 'transparent',
                      color: '#FFF',
                      padding: 0,
                      margin: 0,
                      border: 0,
                    }}
                    data-toggle="modal"
                    data-target="#modal-faq">
                    FAQ
                  </button>
                </div>
                <div className="text-white mt-2  wl-footer-link">
                  <a href={links.tc} target="_blank" rel="noreferrer">
                    Terms of Use
                  </a>
                </div>
                <div className="text-white mt-2  wl-footer-link">
                  <a href={links.MasterCardTerms} target="_blank" rel="noreferrer">
                    CL Card Terms
                  </a>
                </div>
                <div className="text-white mt-2  wl-footer-link">
                  <a href={links.PrivacyPolicy} target="_blank" rel="noreferrer">
                    Privacy Pollicy
                  </a>
                </div>
                <div className="text-white mt-2  wl-footer-link">
                  <a href={links.OptimusCardPrivacyPolicy} target="_blank" rel="noreferrer">
                    Issuer Privacy Policy
                  </a>
                </div>
              </div>
              <div
                className="col-12 col-md-12 col-xl-3 d-flex flex-column justify-content-start"
                style={{ marginBottom: isMobile ? '3.438rem' : '0', paddingLeft: '1.75rem' }}>
                <div className="text-white wl-footer-header mb-1">Contact</div>
                <div className="text-white mt-2  wl-footer-link">
                  <a href={links.working_with_us} target="_blank" rel="noreferrer">
                    Work with us
                  </a>
                </div>
              </div>
              <div
                className="col-12 col-md-12 col-xl-3 d-flex flex-column justify-content-start"
                style={{ marginBottom: isMobile ? '3.438rem' : '0', paddingLeft: '1.75rem' }}>
                <div className="text-white wl-footer-header mb-1">Resources</div>
                <div className="text-white mt-2  wl-footer-link">
                  <a href={links.documentation} target="_blank" rel="noreferrer">
                    API’s documentation
                  </a>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <div className="flex-row justify-content-between align-items-center bg-dark py-4">
        <Fade bottom>
          <div className={`d-flex flex-wrap row ${isMobile ? '' : 'mt-5'}`}>
            {!isMobile && (
              <div
                className="col-16 col-md-4 text-white text-privacy"
                style={{ marginTop: '2.5rem', textAlign: isMobile ? 'left' : '' }}>
                © Crypto Life Card. All rights reserved.
              </div>
            )}
            <div
              className={`col-16 col-sm-4 col-md-4 d-flex flex-row ${
                isMobile ? 'justify-content-center' : 'justify-content-end'
              }`}
              style={{ marginTop: '2.5rem', marginLeft: isMobile ? '' : 'auto' }}>
              <a href={links.linkedin} target="_blank" rel="noreferrer">
                <LinkedIn style={{ width: 20, height: 20, marginLeft: 25 }} />
              </a>
              <a href={links.facebook} target="_blank" rel="noreferrer">
                <Facebook style={{ width: 20, height: 20, marginLeft: 25 }} />
              </a>
              <a href={links.twitter} target="_blank" rel="noreferrer">
                <Twitter style={{ width: 20, height: 20, marginLeft: 25 }} />
              </a>
            </div>
            {isMobile && (
              <div
                className="col-16 col-md-4 text-white text-privacy"
                style={{ marginTop: '2.5rem', textAlign: isMobile ? 'left' : '' }}>
                © Crypto Life Card. All rights reserved.
              </div>
            )}
          </div>
        </Fade>
      </div>
    </div>
  </SectionContainer>
);

export default Footer;
